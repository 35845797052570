.button {
    display: flex;
    align-items: center;
    padding: 6px 24px;
    text-decoration: none;
    color: black;
    border: 2px solid #051d40;
    border-radius: 24px;

    &.btn-outline {
        color: #051d40;
        border: 2px solid #051d40;
    }

    &.btn-filled {
        color: white;
        background-color: #051d40;
    }
}
