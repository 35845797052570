.App {
    font-family: sans-serif;
    text-align: center;
}
.content {
    position: relative;
}
.circle {
    stroke-dasharray: 1194;
    stroke-dashoffset: 1194;
    animation: dc 1s ease-in-out;
    animation-fill-mode: forwards;
}
.tick {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation: dt 0.8s ease-out;
    animation-fill-mode: forwards;
    animation-delay: 0.95s;
}
@keyframes dc {
    from {
        stroke-dashoffset: 1194;
    }
    to {
        stroke-dashoffset: 2388;
    }
}
@keyframes dt {
    from {
        stroke-dashoffset: 350;
    }
    to {
        stroke-dashoffset: 0;
    }
}
